import {Component, OnInit} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {AcctItemMapper, acctItemsMapper} from '../../../../../../backend/src/constants/AcctItemMapper';
import {OutGetV1RecordsDTO} from '../../../../../../backend/dist/services/TransService';
import {accountService, bankService, transService} from "../../../../sdk";
import {CategoryType} from '../../../../../../backend/dist/constants/enum';
import {CreateDialogComponent} from "../../banking/create-dialog/create-dialog.component";
import {InPostV1RecordDTO} from '../../../../../../backend/dist/services/BankService';
import {OutPostV1FileDTO} from '../../../../../../backend/dist/services/UploadService';
import {OutUploadRefDialog, UploadRefDialogComponent} from "../upload-ref-dialog/upload-ref-dialog.component";
import * as moment from "moment";


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit{

  selectedItem!: AcctItemMapper
  checked!: boolean

  categoryType = CategoryType

  selectedCategoryType!: CategoryType

  checkingItem!: OutGetV1RecordsDTO | null

  isVisible = false

  listOfAcctItem: AcctItemMapper[] = acctItemsMapper
  recordList!: OutGetV1RecordsDTO[]
  filteredList!: OutGetV1RecordsDTO[]

  async ngOnInit(): Promise<void> {

    const res = await transService.getV1Records({})

    this.recordList = res.data

    this.filteredList = this.recordList
  }

  constructor(private modalService: NzModalService) {
  }

  onSelectType(obj: AcctItemMapper){

    if(obj == null){}

    console.log(obj)
  }

  onSelectStatus(obj: boolean){

    if(obj == null){}

  }

  onChange(){

    this.filteredList = this.recordList.filter((ele)=>{

      if(this.selectedItem == null){

        return true

      } else {

        return this.selectedItem.acctItem == ele.acctItem

      }
    }).filter((ele)=>{

      if(this.checked == null){

        return true

      } else {

        if(this.checked){

          return ele.checked

        } else {

          return ele.canChecking
        }
      }
    }).filter((ele)=>{

      if(this.selectedCategoryType == null){

        return true

      } else {

        return this.selectedCategoryType == ele.categoryType
      }
    })
  }

  onChecking(obj: OutGetV1RecordsDTO){

    this.checkingItem = obj

    this.isVisible = true;

  }

  handleCancel(){

    this.checkingItem = null

    this.isVisible = false;
  }

  handleBank(checkingItem: OutGetV1RecordsDTO | null){

    this.isVisible = false;
    const inPostV1RecordDTO: InPostV1RecordDTO = {
      amount: this.checkingItem?.amount!,
      bank_id: 0,
      description: this.checkingItem?.description!,
      trans_datetime: "",
      transaction_ref: ""
    }

    const dialogRef = this.modalService.create({
        nzTitle: '創建銀行對帳單',
        nzWidth: '150vh',
        nzContent: CreateDialogComponent,
        nzFooter: null,
        nzComponentParams: {
          defaultInputField: inPostV1RecordDTO,
          defaultType: this.checkingItem?.categoryType == CategoryType.EXPENSES? -1: 1,
          listOfField: [
            {formId: "transaction_ref", formField: "交易編號", required: true},
            {formId: "description", formField: "詳細", required: true},
            {formId: "amount", formField: "總額", required: true},
          ],
        }
      }
    )
    dialogRef.afterClose.subscribe(async (ele: InPostV1RecordDTO) => {

      if(ele){
        const res = await bankService.postV1Record(ele);

        const r = await accountService.postV1Checking({
          acctItem: this.checkingItem?.acctItem!,
          banking_statement_id: res.data.bank_statement_id,
          description: this.checkingItem?.description!,
          payment_ref_id: undefined,
          source_id: this.checkingItem?.source_id!,
          transaction_date: moment(ele.trans_datetime).toISOString()
        })

        await this.ngOnInit()
      }
    });
  }


  handleUpload(checkingItem: OutGetV1RecordsDTO | null){

    this.isVisible = false;

    const dialogRef = this.modalService.create({
        nzTitle: '上傳交易憑證',
        nzWidth: '150vh',
        nzContent: UploadRefDialogComponent,
        nzFooter: null,
      }
    )

    dialogRef.afterClose.subscribe(async (ele: OutPostV1FileDTO & OutUploadRefDialog) => {

      if(ele){

        const res = await transService.postV1Receipt({
          amount: this.checkingItem?.amount!,
          ref_file: ele.fileName,
          trans_datetime: moment(ele.date).toISOString()
        })

        const r = await accountService.postV1Checking({
          acctItem: this.checkingItem?.acctItem!,
          banking_statement_id: undefined,
          description: this.checkingItem?.description!,
          payment_ref_id: res.data.payment_ref_id,
          source_id: this.checkingItem?.source_id!,
          transaction_date: moment(ele.date).toISOString()
        })

        await this.ngOnInit()

      }
    });


  }
}
