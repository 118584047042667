import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../auth/auth.service";
import {ActivatedRoute} from "@angular/router";
import {
  InPostV1ShiftDTO,
  OutGetV1RecordDTODetails,
  OutGetV1RecordsDTO
} from '../../../../../../backend/dist/services/AttendService';
import { UserRoles } from '../../../../../../backend/dist/constants/enum';
import {attendService} from "../../../../sdk";
import * as moment from "moment";
import {NzModalService} from "ng-zorro-antd/modal";
import {CreateDialogComponent} from "../approval/create-dialog/create-dialog.component";
import {EditDialogComponent} from "./edit-dialog/edit-dialog.component";

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  userId!: number;


  summary!: OutGetV1RecordsDTO;
  details!: OutGetV1RecordDTODetails[]
  selectedDate: Date = new Date();
  period!: string;


  constructor(private modalService: NzModalService ,public authService: AuthService, private route: ActivatedRoute) {
  }


  async ngOnInit(){

    if(this.authService.getCredentials()?.role == UserRoles.ADMIN){

      this.userId = Number(this.route.snapshot.paramMap.get("id"))

    } else {

      this.userId = this.authService.getCredentials()?.id!
    }

    await this.onDateChange(this.selectedDate)
  }

  async onDateChange(newDate: Date) {


    const res = await attendService.getV1Record({

        month: Number(moment(this.selectedDate).format("MM")),
        user_id: this.userId,
        year: Number(moment(this.selectedDate).format("YYYY"))
      }
    )
    this.period = moment(this.selectedDate).format("YYYY-MM")

    this.details = res.data.details;
    this.summary = res.data.summary;
  }

  async onEdit(obj: OutGetV1RecordDTODetails){

    const dialogRef = this.modalService.create({
      nzTitle: '修改出勤記錄',
      nzWidth: '150vh',
      nzContent: EditDialogComponent,
      nzFooter: null
    });

    dialogRef.afterClose.subscribe(async (ele:{
      sign_in_time: string,
      sign_out_time: string
    }) => {

      if (ele) {

        await attendService.putV1Record({
          sign_date: obj.date,
          sign_in_time: ele.sign_in_time,
          sign_out_time: ele.sign_out_time,
          user_id: this.userId
        })

        alert("修改成功!")
        await this.ngOnInit()
      }
    });
  }
}
