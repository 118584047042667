export enum UserRoles{
    Guest, // 0
    ADMIN , // 1
    MANAGER, // 2
    EMPLOYEE // 3
}

export enum ProjectStatus{
    UNASSIGNED,
    PROGRESSING ,
    COMPLETED,
    DELETED
}

export enum TaskStatus{
    UNASSIGNED,
    PROGRESSING ,
    COMPLETED,
    DELETED
}

export enum OutSourceType{
    PENDING,
    PROGRESSING,
    COMPLETED,
    DELETED

}

export enum CustomerSource{
    OTHER,
    FACEBOOK,
    IG,
    TV,
    PHONE
}

export enum CustomerStatus{
    POTENTIAL,
    EXISTING
}

export enum TaskVisible{
    INVISIBLE,
    DEPARTMENT,
    ASSIGNED
}

export enum OrderStatus{
    ORDERED,
    CANCELED,
    PAID
}

export enum AttendStatus{
    NORMAL,
    LATE,
    LEAVE_EARLY,
    LATE_LEAVE_EARLY,
    LEAVE,
    SCHEDULED
}

export enum Status{
    REJECTED,
    ACCEPTED,
    PENDING
}

export enum LeaveType{
    ANNUAL_LEAVE,
    SICK_LEAVE,
    UNPAID_LEAVE
}

export enum ReimburseType{
    OTHERS,
    TRANSPORTATION,
    TRAVEL,
    MEAL
}

export enum ReimburseStatus{
    REJECTED,
    APPROVED,
    PENDING,
    PAID,
    UNPAID
}

export enum PurchaseStatus{
    UNPAID,
    PAID,
    RECEIVED,
    CANCELED
}

export enum AccountType{
    EXPENSE,
    ASSET,
    INCOME,
    LIABILITIES,
    EQUITY
}

export enum CategoryType{

    EXPENSES,
    REVENUE
}

export enum ExpenseStatus{

    UNPAID,
    PAID,
    CANCELED
}

export enum RevenueStatus{

    UNRECEIVED,
    RECEIVED,
    CANCELED
}

export enum Account{
    CASH = 1,
    RECEIVABLE,
    INVENTORY,
    FIXED_ASSETS,
    PAYABLE,
    LT_LIABILITIES,
    OPERATING_EXPENSE,
    PURCHASING,
    SALES,
    NON_OPERATING_INCOME
}

export enum Category{
    SALES_REVENUE = 1,
    RENT_EXPENSE,
    ADVERTISING_AND_MARKETING_EXPENSE,
    OFFICE_SUPPLIES_EXPENSE,
    SALARY_EXPENSE,
    INVENTORY_EXPENSE,
    UTILITIES_EXPENSE,
    OTHER_REVENUE,
    OTHER_EXPENSE,

}

export enum AcctItem {

    OTHER_REVENUE,
    OTHER_EXPENSE,
    SALARIES,
    ORDER,
    PURCHASE,
    CONSULTANT
}
export enum SalariesStatus {

    UNPAID,
    PAID,
}

export enum ShiftApplyStatus {

    REJECTED,
    ACCEPTED,
    PENDING
}