import {AcctItem} from "./enum";

export type AcctItemMapper = {

    acctItem: AcctItem
    acctItemName: string
    acctItemNameCHT: string
}


export const acctItemsMapper: AcctItemMapper[] = [
    {
        acctItem: AcctItem.PURCHASE,
        acctItemName: AcctItem[AcctItem.PURCHASE],
        acctItemNameCHT: "採購"
    },
    {
        acctItem: AcctItem.ORDER,
        acctItemName: AcctItem[AcctItem.ORDER],
        acctItemNameCHT: "訂單"

    },
    {
        acctItem: AcctItem.SALARIES,
        acctItemName: AcctItem[AcctItem.SALARIES],
        acctItemNameCHT: "薪水"
    },
    {
        acctItem: AcctItem.OTHER_EXPENSE,
        acctItemName: AcctItem[AcctItem.OTHER_EXPENSE],
        acctItemNameCHT: "其他開支"
    },
    {
        acctItem: AcctItem.OTHER_REVENUE,
        acctItemName: AcctItem[AcctItem.OTHER_REVENUE],
        acctItemNameCHT: "其他收入"
    }
]